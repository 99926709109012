// Config.js
export const Config = {
  SELECTORS: {
    burger: ".hamburger",
    menu: "#slide-menu",
    spriteSheet: ".wasp-logo-spritesheet",
    clock: ".the-time",
    year: ".current-year",
    hackedText: ".link",
    canvas: "#canvas3d",
    waspPlayer: "#wasp-reel",
    contactPlayer: "#contact-reel",
    fader: ".fader",
    error404: ".error404",
    videoWindow: "#video-window",
  },

  URLS: {
    spline: import.meta.env.VITE_SPLINE_URL,
  },

  VIMEO_IDS: {
    waspVideoId: "1001135510",
    contactVideoId: "1007518594",
  },

  PLYR_OPTIONS: {
    home: {
      controls: ["volume", "mute"],
      autoplay: true,
      autopause: false,
      hideControls: false,
      fullscreen: { enabled: false },
      tooltips: { controls: false, seek: false },
      iconUrl: "sprite.svg",
      iconPrefix: "audio",
      loop: { active: true },
      muted: true,
      storage: { enabled: false },
      vimeo: {
        loop: true,
        muted: true,
        autoplay: true,
        controls: false,
        dnt: true,
        fullscreen: false,
      },
    },
  },

  SETTINGS: {
    revealDelay: 100,
    error404Timeout: 2500,
    scrollSpeed: 2000,
    logoFadeSpeed: 1,
    keyboardDebounceTime: 200,
  },
};
